
// 👇 API EndPoints
import { urlIdentityService, urlTimeSheetService, urlDriverService } from '@src/endpoints'

import { getUserUriParams, getUriParams } from '@src/utility/Utils'

// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'

// 👇 Third-Party component
import toast from 'react-hot-toast'

// 👇 Components
import { ToastContent } from "@src/views/components/toast/ToastContent"

const ToastMessage = (heading, message, isSuccess) => {
  toast(t => (
    <ToastContent t={t} heading = {heading} response = {isSuccess} message = {message} />
  ))
}

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get(`${urlIdentityService}/GetAllUsersCount`)
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async (params) => {
  const { companyName, isBlockedDriver, ...restParams } = params
  const uri = `${getUserUriParams(restParams)}`
  let url = `${urlIdentityService}/GetAllUsers?${uri}`

  if (companyName) {
    url += `&companyName=${encodeURIComponent(companyName)}`
  }
  if (typeof isBlockedDriver === 'boolean') {
    url += `&isBlockedDriver=${isBlockedDriver}`
  }

  const response = await axios.get(url)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount,
    loaded: true
  }
})

export const getTruckDriverData = createAsyncThunk('appUsers/getTruckDriverData', async data => {
  const { params, filter } = data
  const uri = getUriParams(params)
  const filterData = {
    driverNameSearch: filter.driverNameSearch || null,
    truckCompanyId: filter.truckCompanyId || 0,
    driverStatus: filter.driverStatus || null,
    blockStatus: filter.blockStatus || null
  }
  const response = await axios.post(`${urlDriverService}/GetTruckDrivers?${uri}`, filterData)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount,
    filterData
  }
})

export const getTruckDriverCount = createAsyncThunk('appUsers/getTruckDriverCount', async () => {
  const response = await axios.get(`${urlDriverService}/GetTruckDriverCount`)
  return {
    data: response.data
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get(`${urlIdentityService}/GetUserDetailsById/${id}`)
  return {
    data: response.data,
    userLoaded: true
  }
})

export const getAvatar = createAsyncThunk('appUsers/getAvatar', async id => {
  const response = await axios.get(`${urlIdentityService}/GetAvatar/${id}`)
  return {
    data: response.data.data[0].avatar,
    userAvatarLoaded: true
  }
})

export const getUserRolesList =  createAsyncThunk('appUsers/getUserRolesList', async (userRolesTypeViewModel) => {
  const response = await axios.post(`${urlIdentityService}/GetUserRolesList`, userRolesTypeViewModel)
  return response.data.data
})

export const getAllDepartment =  createAsyncThunk('appUsers/getAllDepartment', async () => {
  const response = await axios.get(`${urlIdentityService}/GetAllDepartment`)
  const department = [{ value: '', label: 'Select Department' }, ...response.data.data]
  return department
})

export const getAllDepartmentList =  createAsyncThunk('appUsers/getAllDepartmentList', async () => {
  const response = await axios.get(`${urlIdentityService}/GetAllDepartmentList`)
  return {
    loadedDepList: true,
    depList: response.data.data
  }
})

export const getAllRoles =  createAsyncThunk('appUsers/getAllRoles', async () => {
  const response = await axios.get(`${urlIdentityService}/GetAllRoles`)
  const roles = [{ value: '', label: 'Select Role' }, ...response.data.data]
  return roles
})

export const getAllRolesList =  createAsyncThunk('appUsers/getAllRolesList', async () => {
  const response = await axios.get(`${urlIdentityService}/GetAllRolesList`)
  return {
    loadedRoleList: true,
    roleList: response.data.data
  }
})

export const getTimeSheetListByProfileId = createAsyncThunk('appUsers/getTimeSheetListByProfileId', async profileId => {
  const response = await axios.get(`${urlTimeSheetService}/GetTimeSheetByProfileId?profileId=${profileId}`)
  return response.data.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post(`${urlIdentityService}/Register`, user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const updateUser = createAsyncThunk('appUsers/updateUser', async (userProfileUpdateViewModel, {dispatch}) => {
  const response = await axios.put(`${urlIdentityService}/UserProfileUpdate`, userProfileUpdateViewModel)
  response.data.success === true ? ToastMessage('Profile', response.data.messages[0], true) : ToastMessage('Profile', response.data.errors[0], false)
  await dispatch(getUser(userProfileUpdateViewModel.id))
  return userProfileUpdateViewModel
})

export const userPromotion = createAsyncThunk('appUsers/userPromotion', async (userPromotionViewModel, {dispatch}) => {
  const response = await axios.put(`${urlIdentityService}/UserPromotion`, userPromotionViewModel)
  response.data.success === true ? ToastMessage('Promotion', response.data.messages[0], true) : ToastMessage('Promotion', response.data.errors[0], false)
  await dispatch(getUser(userPromotionViewModel.id))
  return userPromotionViewModel
})


export const cardAssign = createAsyncThunk('appUsers/cardAssign', async (cardAssignViewModel, {dispatch}) => {
  await axios.put(`${urlIdentityService}/CardAssign`, cardAssignViewModel)
  await dispatch(getUser(cardAssignViewModel.userId))
  return cardAssignViewModel
})

export const updateUserAvatar = createAsyncThunk('appUsers/updateUserAvatar', async (UpdateUserAvatarViewModel, {dispatch}) => {
  const response = await axios.put(`${urlIdentityService}/UpdateUserAvatar`, UpdateUserAvatarViewModel)
  await dispatch(getUser(UpdateUserAvatarViewModel.id))
  return response
})

export const updateUserAccess = async (updateUserAcessViewModel) => {
  await axios.put(`${urlIdentityService}/UpdateUserAccess`, updateUserAcessViewModel)
  return updateUserAcessViewModel
}

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const suspendUser = createAsyncThunk('appUsers/suspendUser', async (userId, {dispatch}) => {
  await axios.delete(`${urlIdentityService}/SuspendUser/${userId}`)
  dispatch(getUser(userId))
})

export const reinstateUser = createAsyncThunk('appUsers/ReinstateUser', async (userId, {dispatch}) => {
  await axios.patch(`${urlIdentityService}/ReinstateUser/${userId}`)
  dispatch(getUser(userId))
})

export const addPassword = createAsyncThunk('appUsers/addPassword', async (addPasswordViewModel) => {
  const response = await axios.patch(`${urlIdentityService}/AddPassword`, addPasswordViewModel)
  response.data.success === true ? ToastMessage('Password', response.data.messages[0], true) : ToastMessage('Password', response.data.errors[0], false)
})

export const createRole = createAsyncThunk('appUsers/createRole', async (createRoleViewModel, { dispatch }) => {
  await axios.post(`${urlIdentityService}/CreateRole`, createRoleViewModel)
  await dispatch(getAllRolesList())
})

export const deleteRole = createAsyncThunk('appUsers/deleteRole', async (roleName, { dispatch }) => {
  await axios.delete(`${urlIdentityService}/DeleteRole/${roleName}`)
  await dispatch(getAllRolesList())
})

export const addRole = createAsyncThunk('appUsers/addRole', async (addRoleModel, {dispatch}) => {
  await axios.post(`${urlIdentityService}/AddRole`, addRoleModel)
  await dispatch(getUserRolesList(addRoleModel.UserId))
})

export const removeRole = createAsyncThunk('appUsers/removeRole', async (removeRoleModel, {dispatch}) => {
  await axios.patch(`${urlIdentityService}/RemoveRole`, removeRoleModel)
  await dispatch(getUserRolesList(removeRoleModel.UserId))
})

export const createDepartment = createAsyncThunk('appUsers/createDepartment', async (createDepartmentViewModel, { dispatch }) => {
  await axios.post(`${urlIdentityService}/CreateDepartment`, createDepartmentViewModel)
  await dispatch(getAllDepartmentList())
})

export const deleteDepartment = createAsyncThunk('appUsers/deleteDepartment', async (id, { dispatch }) => {
  await axios.delete(`${urlIdentityService}/DeleteDepartment/${id}`)
  await dispatch(getAllDepartmentList())
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    loaded : false,
    userLoaded: false,
    loadedDepList : false,
    loadedRoleList : false,
    userAvatarLoaded : false,
    data: [],
    total: 1,
    params: {},
    allData: [],
    allDepartment:[],
    allDepartmentList: [],
    allRoles:[],
    allRolesList: [],
    uerRolesList: [],
    selectedUser: null,
    avatar: null,
    userTimeSheetList: [],
    truckDriverCount: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.pending, (state) => {
        state.userLoaded = false
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.userLoaded = true
        state.selectedUser = action.payload.data
      })
      .addCase(getAvatar.pending, (state) => {
        state.userAvatarLoaded = false
      })
      .addCase(getAvatar.fulfilled, (state, action) => {
        state.userAvatarLoaded = true
        state.avatar = action.payload.data
      })
      .addCase(getAllDepartment.fulfilled, (state, action) => {
        state.allDepartment = action.payload
      })
      .addCase(getAllDepartmentList.pending, (state) => {
        state.loadedDepList = false
      })
      .addCase(getAllDepartmentList.fulfilled, (state, action) => {
        state.loadedDepList = true
        state.allDepartmentList = action.payload.depList
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.allRoles = action.payload
      })
      .addCase(getAllRolesList.pending, (state) => {
        state.loadedRoleList = false
      })
      .addCase(getAllRolesList.fulfilled, (state, action) => {
        state.loadedRoleList = true
        state.allRolesList = action.payload.roleList
      })
      .addCase(getUserRolesList.fulfilled, (state, action) => {
        state.uerRolesList = action.payload
      })
      .addCase(getTimeSheetListByProfileId.fulfilled, (state, action) => {
        state.userTimeSheetList = action.payload
      })
      .addCase(getTruckDriverData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.filter = action.payload.filterData
      })
      .addCase(getTruckDriverCount.fulfilled, (state, action) => {
        state.truckDriverCount = action.payload.data
      })
  }
})

export default appUsersSlice.reducer
