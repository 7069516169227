// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// 👇 Axios Imports
import axios from 'axios'
// 👇️ Endpoints
import { urlOceanBookingService, urlConfigCacheService, urlVesselVoyageService, urlCRMCompanyService, urlFreightForwardService } from '@src/endpoints'
// 👇️ Utils
import { getUriParams } from '@utils'
import toast from 'react-hot-toast'
// 👇 Components
import { ToastContent } from "@src/views/components/toast/ToastContent"

const ToastMessage = (heading, message, isSuccess) => {
  toast(t => (
    <ToastContent t={t} heading = {heading} response = {isSuccess} message = {message} />
  ))
}

//function to remove all null, undefined, empty string from object else make the value caps
function clean(obj) {
  if (!obj) return {}
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName]
    } else if (typeof obj[propName] === 'string') {
      obj[propName] = obj[propName].toUpperCase()
    }
  }
  return obj
}
export const getData = createAsyncThunk('oceanBooking/getAllVesselVoyageByTerminal', async data => {
  const { params, filterForm } = data
  const uri = getUriParams(params)

  // Set default values for page and perPage if they are not present in filterForm
  const {  ...restFilterForm } = filterForm

  const response = await axios.post(`${urlOceanBookingService}/GetAllOceanBookingsList?${uri}`, {
    ...clean(restFilterForm),
    id: ""
   
  })
 
   return {
    params,
    terminal: response.data.data.terminal === '' ? {} : response.data.data.terminal,
    data: response.data.data || [],
    totalPages: response.data.totalCount || 0,
    loaded: true,
    filterForm
  }
})
export const getVoyageID = async data => {
  const response = await axios.post(`${urlVesselVoyageService}/GetAllVoyage?`, { id: "", ...data })
  if (!response.data.success) return {
    success: false, message: 'No Voyage found'
  }
  return { success: true, data: response.data.data[0]?.id }
}

export const getCustomers = createAsyncThunk('oceanBooking/getCustomers', async () => {
  const response = await axios.get(`${urlCRMCompanyService}/GetAllCompanyRolesSelect/CUSTOMER`)
  const regions = await axios.get(`${urlConfigCacheService}/GetAllSelect/RegionCity`)
  const consignee = await axios.get(`${urlConfigCacheService}/Consignee`)
  const containerSizes = await axios.get(`${urlConfigCacheService}?type=ContainerSize`)
  const ff = await axios.get(`${urlCRMCompanyService}/GetAllCompanyTypesSelect/Freight%20Forwarder`)
    const bookingType = await axios.get(`${urlConfigCacheService}/BookingType`)
  const bookingStatus = await axios.get(`${urlConfigCacheService}/BookingStatus`)
  const vesselName = await axios.post(`${urlVesselVoyageService}/GetAllVessel`, { Id: '' }, // Empty data object if no request body is required
    {
      headers: {
        'Content-Type': 'application/json' // Set the Content-Type header
      }
    }
  )
  const containerSizesList = [{ value: '', label: 'Select...' }, ...(containerSizes.data.map(el => ({ value: el.id, label: el.id })))]
    const customers = [{ value: '', label: 'Select...' }, ...(response.data.map(el => ({ value: `${el.companyCode} - ${el.companyName}`, label: `${el.companyCode} - ${el.companyName}` })))]
  const regionsList = [{ value: '', label: 'Select...' }, ...(regions.data.data.map(el => ({ value: el.label, label: el.label })))]
  const consigneeList = [{ value: '', label: 'Select...' }, ...(consignee.data.map(el => ({ value: el.name, label: el.name })))]
  const ffList = [{ value: '', label: 'Select...', isForwarderOnly: '' }, ...(ff.data.map(el => ({ value: el.value, label: el.companyCode, isForwarderOnly: el.isForwarderOnly})))]
  const bookingTypeList = [{ value: '', label: 'Select...' }, ...(bookingType.data.map(el => ({ value: el.name, label: el.name })))]
  const statusList = [{ value: '', label: 'Select...' }, ...(bookingStatus.data.map(el => ({ value: el.name, label: el.name })))]
  const vesselList = [{ value: '', label: 'Select...' }, ...(vesselName.data.data.map(el => ({ value: el.name, label: el.name })))]
  return { customers, regions: regionsList, consignee: consigneeList, ff: ffList, bookingType: bookingTypeList, bookingStatus: statusList, vesselName: vesselList, containerSizes: containerSizesList }
})

//GET OCEAN BOOKING BY ID
export const getOceanBookingById = createAsyncThunk('oceanBooking/GetOceanBookingsId', async (id) => {
  const response = await axios.get(`${urlOceanBookingService}/GetOceanBookingsId/${id}`)
     return {
    selectedOceanBooking: response.data,
    loadedOceanBooking: true
  
  }
})


// /AddOrUpdateOceanBookings -post
export const addOceanBooking = createAsyncThunk('oceanBooking/AddOrUpdateOceanBookings', async (data, { dispatch }) => {
  const { oceanBookingData, params, filterForm, id } = data
 // Capture the response from the API call
 const response = await axios.post(`${urlOceanBookingService}/AddOrUpdateOceanBookings`, { ...oceanBookingData, id })
  /* eslint-disable no-unused-vars */
 const oceanId = response.data.data.id
 
   await dispatch(getOceanBookingById(oceanBookingData.id))
  await dispatch(getData({ params, filterForm }))
  return {
    oceanBookingData: response.data.data,
    oceanId
  }
  
})

//api call for adding FF Order
export const addFFRequest = createAsyncThunk('ffRequests/addFFRequest', async (FFRequestViewModel, { dispatch, getState }) => {
  const response = await axios.post(`${urlFreightForwardService}/AddFFRequest`, FFRequestViewModel)
  
  await dispatch(getData(getState().freightForward.params))
  await dispatch(getAllData())
  //await dispatch(getFFResponse(response))
  return {
    ffResponse: response
  } 
})

 //api call to update bulk vesselVoyage pairs in multiple rows
  
export const updateBulkVesselVoyage = createAsyncThunk('oceanBooking/UpdateBookingVesselCutOff', async (data, { dispatch }) => {
  const { updatedVesselData, params, filterForm } = data
  await axios.post(`${urlOceanBookingService}/UpdateBookingVesselCutOff`, {
    ...updatedVesselData,
    id: ''
  })
  await dispatch(getData({ params, filterForm }))
  return updatedVesselData
})


// /ActivateOceanBookings -patch
export const activateVessel = createAsyncThunk('oceanBooking/ActivateOceanBookings', async (data, { dispatch }) => {
  const { id, params, filterForm } = data
  await axios.patch(`${urlOceanBookingService}/ActivateOceanBookings?id=${id}`)
  await dispatch(getData({ params, filterForm }))
  return id
})

// /DeactivateOceanBookings -patch
export const deactivateVessel = createAsyncThunk('oceanBooking/DeactivateOceanBookings', async (data, { dispatch }) => {
  const { id, params, filterForm } = data
  await axios.patch(`${urlOceanBookingService}/DeactivateOceanBookings?id=${id}`)
  await dispatch(getData({ params, filterForm }))
  return id
})

// /DeleteOceanBookings -delete
export const deleteVessel = createAsyncThunk('oceanBooking/deleteVessel', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${urlOceanBookingService}/DeleteOceanBookings/${id}`)
  response.data.success === true ? ToastMessage('Promotion', response.data.messages[0], true) : ToastMessage('Promotion', response.data.errors[0], false)
  // Fetch the current state of filterForm along with params
  const { params, filterForm } = getState().oceanBooking
  await dispatch(getData({ params, filterForm }))
  return id
})

export const oceanBookingSlice = createSlice({
  name: 'oceanBooking',
  initialState: {
    loaded: false,
    loadedAll: false,
    loadedFFR: false,
    data: [],
    terminal: {},
    total: 1,
    params: {},
    filterForm: {},
    allData: {},
    status: [],
    notes: [],
    allTimeSheetUser: [],
    allContacts: [],
    customers: [],
    consignee: [],
    freightForwarder: [],
    regions: [],
    bookingType: [],
    bookingStatus: [],
    vesselName: [],
    containerSizes: [],
    selectedOceanBooking: null,
    loadedOceanBooking: false,
    oceanId:"",
    createdEmployee:""
    
  },
 
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.terminal = action.payload.terminal
        state.filterForm = action.payload.filterForm
        
      })
      .addCase(addOceanBooking.fulfilled, (state, action) => {
        state.selectedOceanBooking = action?.payload?.oceanBookingData
        state.oceanId = action?.payload?.oceanBookingData?.id
        state.createdEmployee = action?.payload?.oceanBookingData?.createdBy
      })
      .addCase(getData.rejected, (state) => {
        state.loaded = true
        state.data = []
        state.params = {}
        state.total = 0
      })
      .addCase(getOceanBookingById.fulfilled, (state, action) => {
        state.selectedOceanBooking = action.payload.selectedOceanBooking
        state.loadedOceanBooking = action.payload.loadedOceanBooking
      })
     
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.customers = action.payload.customers
        state.consignee = action.payload.consignee
        state.freightForwarder = action.payload.ff
        state.containerSizes = action.payload.containerSizes
        state.regions = action.payload.regions
        state.bookingType = action.payload.bookingType
        state.bookingStatus = action.payload.bookingStatus
        state.vesselName = action.payload.vesselName
      })
      //anything fulfilled will set loaded to true
      .addMatcher(action => action.type.endsWith('/fulfilled'), (state) => {
        state.loaded = true
      })
      //anything pending will set loaded to false
      .addMatcher(action => action.type.endsWith('/pending'), (state) => {
        state.loaded = false
      })
      //reset loaded to true if rejected
      .addMatcher(action => action.type.endsWith('/rejected'), (state) => {
        state.loaded = true
      })

  }
})

export default oceanBookingSlice.reducer