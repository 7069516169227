// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// 👇 Axios Imports
import axios from 'axios'
import { urlOceanBookingService, urlFreightForwardService, urlLandBookingService } from '@src/endpoints'
import { getFFRequest } from "./index"

//geting ocean booking data
export const getData = createAsyncThunk('oceanBookings/getData', async params => {
    const OceanBookingFFOrderAssignFilter = {   
      destination : params.ffRequest.destination,
      origin : params.ffRequest.source,
      bookingNumber:'',
      customer:'', //params.ffRequest.companyName
      totalQuantityRemaining : params.ffRequest.containerSizes[0].quantityOfContainerRequested,
      fromDate : params.ffRequest.fromDate,
      toDate : params.ffRequest.toDate
    }
    const response = await axios.post(`${urlOceanBookingService}/GetAllOceanBookingListForFFOrderAssign`, OceanBookingFFOrderAssignFilter)
    const oceanBookings = response.data.data
    const combinedOrderBooking = oceanBookings.map(booking => ({
      FFNumber: params.ffRequest.ffNumber,
      oceanBookings: booking
    }))
    return {
      params,
      data: combinedOrderBooking,
      totalPages: response.data.totalCount,
      loaded: true
    }
  })

  //geting land booking data
  export const getLandBookingData = createAsyncThunk('landBookings/getData', async params => {
    const LandBookingFFOrderAssignFilter = {
      destination : params.ffRequest.destination,
      origin : params.ffRequest.source,
      bookingNumber:'',
      customer:'', //params.ffRequest.companyName
      totalQuantityRemaining : params.ffRequest.containerSizes[0].quantityOfContainerRequested,
      fromDate : params.ffRequest.fromDate,
      toDate : params.ffRequest.toDate
    }
    const response = await axios.post(`${urlLandBookingService}/GetAllLandBookingListForFFOrderAssign`, LandBookingFFOrderAssignFilter)
    const landBookings = response.data.data
    const combinedOrderBooking = landBookings.map(booking => ({
      FFNumber: params.ffRequest.ffNumber,
      landBookings: booking
    }))
    return {
      params,
      data: combinedOrderBooking,
      totalPages: response.data.totalCount,
      loaded: true
    }
  })
  
  export const getAllFFRequest = createAsyncThunk('ffRequests/getData', async params => {
    const uri =  getUriParams(params)
    const FFRequestFilter = {
      id:"",
      ffNumber: params.ffNumber === '' ? null : params.ffNumber,
      
      shipperRefNumber: params.shipperRefNumber === '' ? null : params.shipperRefNumber,
      oceanBookingNumber: params.oceanBookingNumber === '' ? null : params.oceanBookingNumber,
      customer: params.customer?.label,
      containerSizeId: params.containerSizeId?.value,
      fromDate: params.fromDate === '' ? null : params.fromDate,
      toDate: params.toDate === '' ? null : params.toDate,
      destination: params.destination?.label,
      customer: params.customer?.label,
      status: params.ffStatusId?.value
    }
    const response = await axios.post(`${urlFreightForwardService}/GetAllFFRequest?${uri}`, FFRequestFilter)
      return {
      params,
      data: response.data,
      totalPages: response.data.totalCount,
      loaded: true
    }
  })
  export const getFFOrder = createAsyncThunk('ffRequests/getFFOrder', async params => {
    const uri =  getUriParams(params)
    const FFRequestFilter = {
      id:"",
      ffNumber: params.ffNumber === '' ? null : params.ffNumber,
      shipperRefNumber: params.shipperRefNumber === '' ? null : params.shipperRefNumber,
      customerId: params.customerId?.companyId,
      containerSizeId: params.containerSizeId?.value,
      fromDate: params.fromDate === '' ? null : params.fromDate,
      toDate: params.toDate === '' ? null : params.toDate,
      destinationId: params.destinationId?.value,
      status: params.ffStatusId?.value
    }
    const response = await axios.post(`${urlFreightForwardService}/GetAllFFRequest?${uri}`, FFRequestFilter)
      return {
      params,
      data: response.data,
      totalPages: response.data.totalCount,
      loaded: true
    }
  })
  //create and assign ocean booking
  export const addOceanBooking = createAsyncThunk(
    'oceanBooking/AddOrUpdateOceanBookings',
    async (data, { dispatch, getState }) => {
      const { oceanBookingData, ffRequests } = data
      const ffStore = getState().freightForward
  
      // Destructure and create new oceanBookingData object
       /* eslint-disable no-unused-vars */
      const { oceanbookingId, id, ...restOceanBookingData } = oceanBookingData
      const newOceanBookingData = {
        ...restOceanBookingData,
        id: '' // Set id to empty string for new bookings
      }
  
      // Perform API request to add/update bookings and capture the response
      const response = await axios.post(`${urlOceanBookingService}/AddOrUpdateOceanBookings`, newOceanBookingData)
      
      const newId = response?.data?.data?.id
  
      const combinedOrderBooking = {
        ffNumber: ffRequests.ffNumber || ffStore.ffRequest.ffNumber || oceanBookingData.ffNumber || ffStore.ffRequest.FFNumber,
        oceanBookings: {
          ...newOceanBookingData,
          oceanBookingId: newId
        }
      }
  
      // Assign the ocean booking to the FF request
      await axios.post(`${urlFreightForwardService}/AssignFFOceanBooking`, combinedOrderBooking)
  
      // Dispatch actions to update local state
      await dispatch(getFFRequest(ffStore.ffRequest.id))
      const { params } = getState().freightForward
      await dispatch(getAllFFRequest(params))
  
      return response.data
    }
  )

//Land Booking create and assign
export const addLandBooking = createAsyncThunk(
  'landBooking/AddOrUpdateLandBookings',
  async (data, { dispatch, getState }) => {
    const { landBookingData, ffRequests } = data
    const ffStore = getState().freightForward
    // Destructure and create new landBookingData object
     /* eslint-disable no-unused-vars */
    const { landbookingId, id, ...restLandBookingData } = landBookingData
    const newLandBookingData = {
      ...restLandBookingData,
      id: '' // Set id to empty string for new bookings
    }

    // Perform API request to add/update bookings and capture the response
    const response = await axios.post(`${urlLandBookingService}/AddOrUpdateLandBookings`, newLandBookingData)
    
    const newId = response?.data?.data?.id

    const combinedOrderBooking = {
      ffNumber: ffRequests.ffNumber || ffStore.ffRequest.ffNumber || landBookingData.ffNumber || ffStore.ffRequest.FFNumber,
      landBookings: {
        ...newLandBookingData,
        landBookingId: newId
      }
    }

    // Assign the land booking to the FF request
    await axios.post(`${urlFreightForwardService}/AssignFFLandBooking`, combinedOrderBooking)

    // Dispatch actions to update local state
    await dispatch(getFFRequest(ffStore.ffRequest.id))
    const { params } = getState().freightForward
    await dispatch(getAllFFRequest(params))

    return response.data
  }
)


  export const oceanBookingsSlice = createSlice({
    name: 'oceanBookings',
    initialState: {
      loaded: false,
      data: [],
      total: 1,
      params: {}
    },
    
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(getData.pending, (state) => {
          state.loaded = false
        })
        .addCase(getData.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.totalPages
          state.loaded = action.payload.loaded
        })
        .addCase(getLandBookingData.pending, (state) => {
          state.loaded = false
        })
        .addCase(getLandBookingData.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.totalPages
          state.loaded = action.payload.loaded
        })
        .addCase(getAllFFRequest.pending, (state) => {
          state.loaded = false
        })
        .addCase(getAllFFRequest.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.totalPages
          state.loaded = action.payload.loaded
        })
        .addCase(getFFOrder.pending, (state) => {
          state.loaded = false
        })
        .addCase(getFFOrder.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.totalPages
          state.loaded = action.payload.loaded
        })
        
    }
  })
  
  export default oceanBookingsSlice.reducer

  oceanBookingsSlice.metadata = {
    name: 'Zakeer Sainalabdeen',
    github: 'zakeerwtc',
    description: 'Ocean Booking - Store {Get Data from Microservices}',
    filename: 'oceanBookings',
    githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/apps/freight-forward-request/store/oceanBookings.js'
    
  }
  