// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'

import { urlFreightForwardService, urlIdentityService, urlOceanBookingService, urlTerminalService, urlCRMCompanyService, urlReportService, urlEquipmentService } from '@src/endpoints'

import { getUriParams } from '@src/utility/Utils'
import { urlCleanlinessCertificate, urlReportingService } from '../../../../endpoints'

export const getAllData = createAsyncThunk('datasummreport/getTotalsDataSummReport', async params => {
  const DataSummTotalsModel = {
    fromDate : params.fromDate,
    toDate : params.toDate
  }
  const response = await axios.post(`${urlReportService}/getTotalsDataSummReport`, DataSummTotalsModel)
  return {
    data: response.data.data
  }
})

export const getData = createAsyncThunk('datasummreport/getDataSummReport', async params => {
  const uri =  getUriParams(params)
  const DataSummFilter = {
    fromDate : params.fromDate,
    toDate : params.toDate
  }
  const response = await axios.post(`${urlReportService}/getDataSummReport?${uri}`, DataSummFilter)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount
  }
})

export const getFFRequest = createAsyncThunk('ffRequests/getFFRequest', async id => {
  const response = await axios.get(`${urlFreightForwardService}/GetFFRequest/${id}`)
  const customer = await axios.get(`${urlIdentityService}/${response.data[0].customerUserId}`)
  return {
    selectedCustomer: customer.data.data[0],
    ffRequest: response.data[0],
    status: response.data[0].status,
    oceanBookings: response.data[0].oceanBookings,
    notes: response.data[0].notes
  }
})

export const getOceanBookingList = createAsyncThunk('ffRequests/getOceanBookingList', async oceanBookingIds => {
  const response = await axios.post(`${urlOceanBookingService}/getOceanBookingList`, oceanBookingIds)
  return {
    oceanBookingList: response.data.data.allData
  }
})

export const updateFFRequest = createAsyncThunk('ffRequests/updateFFRequest', async (FFRequestUpdateModel, { dispatch }) => {
  await axios.put(`${urlFreightForwardService}/UpdateFFRequest`, FFRequestUpdateModel)
  await dispatch(getFFRequest(FFRequestUpdateModel.id))
  return FFRequestUpdateModel
})

export const updateIngate = createAsyncThunk('movements/updateIngate', async (IngateUpdateModel, { dispatch }) => {
  await axios.put(`${urlTerminalService}/Update`, IngateUpdateModel)
  await dispatch(getFFRequest(IngateUpdateModel.id))
  return IngateUpdateModel
})

export const addNotes = createAsyncThunk('ffRequests/addNotes', async (FFNotesViewModel, { dispatch }) => {
  await axios.post(`${urlFreightForwardService}/AddFFNotes`, FFNotesViewModel)
  await dispatch(getFFRequest(FFNotesViewModel.FFRequestId))
  return FFNotesViewModel
})

export const updateStatus = createAsyncThunk('ffRequests/updateStatus', async (FFStatusUpdateModel, { dispatch }) => {
  await axios.post(`${urlFreightForwardService}/UpdateFFStatus`, FFStatusUpdateModel)
  await dispatch(getFFRequest(FFStatusUpdateModel.FFRequestId))
  return FFStatusUpdateModel
})

export const assignFFOceanBooking = createAsyncThunk('ffRequests/assignFFOceanBooking', async (FFOceanBookingAddModel, { dispatch }) => {
  const OceanBookingNumberModel = {
    OceanBookingNumber : FFOceanBookingAddModel.OceanBookingNumber
  }
  const OceanBookingId = await axios.post(`${urlOceanBookingService}/GetOceanBookingId`, OceanBookingNumberModel)
  const FFOceanBookingAssignModel = {   
    FFRequestId : FFOceanBookingAddModel.FFRequestId,
    OceanBookingId : OceanBookingId.data.data.oceanBookigId,
    notes : FFOceanBookingAddModel.notes,
    noteType : FFOceanBookingAddModel.noteType
  }
  await axios.post(`${urlFreightForwardService}/AssignFFOceanBooking`, FFOceanBookingAssignModel)
  await dispatch(getFFRequest(FFOceanBookingAssignModel.FFRequestId))
  return FFOceanBookingAssignModel
})

export const getShipmentProgress = createAsyncThunk('ReportingService/GetAllShipmentProgressReportList', async (data) => {
  const { params, filter } = data

  const uri = getUriParams(params)

  const filterData = {
    vesselName: filter.vesselName || null,
    voyageNumber: filter.voyageNumber || null,
    cargoCutOffFrom: filter.cargoCutOffFrom || null,
    cargoCutOffTo: filter.cargoCutOffTo || null,
    documentCutOffFrom: filter.documentCutOffFrom || null,
    documentCutOffTo: filter.documentCutOffTo || null,
    vgmCutOffFrom: filter.vgmCutOffFrom || null,
    vgmCutOffTo: filter.vgmCutOffTo || null,
    loadStatus: filter.loadStatus || null,
    docStatus: filter.docStatus || null,
    deliverStatus: filter.deliverStatus || null,
    bookingNumber: filter.bookingNumber || null,
    ffNumber: filter.ffNumber || null
  }

  const response = await axios.post(`${urlReportingService}/GetAllShipmentProgressReportList?${uri}`, filterData)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount,
    filterData
  }
})

export const getShipmentProgressCount = createAsyncThunk('ReportingService/GetShipmentProgressCount', async () => {
  const response = await axios.get(`${urlReportingService}/GetShipmentProgressCount`)
  return {
    data: response.data
  }
})

export const updateShipmentProgressReportcutOffDate = async (data) => {
  const response = await axios.post(`${urlReportingService}/UpdateShipmentProgressReportCuttOffDate`, data)
  if (response.data.success) {
    return {
      success: true,
      data: response.data.data,
      message: response.data.messages
    }
  } else {
    return {
      success: false,
      message: response.data.errors[0]
    }
  }
}

export const getAllCleanlinessCertificateList = createAsyncThunk('CleanlinessCertificate/GetCleanlinessReport', async (data) => {
  const { params, filter } = data

  const uri = getUriParams(params)

  const filterData = {
    transloadOrderNumber: filter.transloadOrderNumber || null,
    bookingNumber: filter.bookingNumber || null,
    vesselName: filter.vesselName || null,
    terminalCode: filter.terminalCode || null,
    voyageNumber: filter.voyageNumber || null,
    customer: filter.customer || null
  }

  const response = await axios.post(`${urlCleanlinessCertificate}/GetAllCleanlinessCertificateList?${uri}`, filterData)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount,
    filterData
  }
})

export const addCleanlinessCertificate = async (data) => {
  const response = await axios.post(`${urlCleanlinessCertificate}/AddCleanlinessCertificate`, data)
  if (response.data.success) {
    return {
      success: true,
      data: response.data.data,
      message: response.data.messages
    }
  } else {
    return {
      success: false,
      message: response.data.errors[0]
    }
  }
}

export const addEquipmentInspectionPicture = async (data) => {
  const response = await axios.post(`${urlEquipmentService}/AddEquipmentInspectionPicture`, data)
  if (response.data.success) {
    return {
      success: true,
      data: response.data.data,
      message: response.data.messages
    }
  } else {
    return {
      success: false,
      message: response.data.errors[0]
    }
  }
}

export const getInboundData = createAsyncThunk('equipment/getData', async data => {
  const { params, filter } = data
  const uri = getUriParams(params)
  const filterData = {
    movementType: 'schedule',
    yardLocation: filter.terminal || "",
    customerName: filter.customerName || "",
    line: filter.line || "",
    referenceNumber: filter.referenceNumber || "",
    equipmentStatus: filter.equipmentStatus || "",
    commodityType: filter.commodityType || "",
    equipmentType: filter.equipmentType || "ALL",
    from: filter.from || null,
    to: filter.to || null,
    destination: filter.destination || "",
    origin: filter.origin || "",
    cargoType: filter.cargoType || "",
    commodity: filter.commodity || "",
    grade: filter.grade || "",
    lotNumber: filter.lotNumber || "",
    lastTracedLocation: filter.lastTracedLocation || "",
    servingYard: filter.servingYard || "",
    tracingStatus: filter.tracingStatus || "",
    equipmentSize: filter.equipmentSize || "",
    shipper: filter.shipper || "",
    status: filter.status || ""
  }
  const response = await axios.post(`${urlEquipmentService}/GetEquipmentByMovementType?${uri}`, filterData)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount,
    filterData
  }
})

export const getInboundTracingReportSummary = createAsyncThunk('equipment/getInboundTracingReportSummary', async (data) => {
  const response = await axios.post(`${urlEquipmentService}/GetInboundTracingReportSummary`, data)
  return {
    data: response.data.data
  }
})

export const getFFReport = createAsyncThunk('ffReports/getFFReport', async (param) => {
  const FFGeneratedReportModel = {
    FFOceanBookingId: param.id,
    ReportType: param.reportType
  }

  const response = await axios.get(`${urlFreightForwardService}/GetOceanBookingsId/${param.id}`)
  const responseReport = param.reportType === 'BookingConfirmation' ? await axios.get(`${urlOceanBookingService}/GetConfirmationReport/${response.data.oceanBookingId}`) : await axios.get(`${urlOceanBookingService}/GetDepartureNoticeReport/${response.data.oceanBookingId}`)
  const responseGenerated = await axios.post(`${urlFreightForwardService}/GetGeneratedReport`, FFGeneratedReportModel)
  const resAllDN = param.reportType === 'BookingConfirmation' ? await axios.get(`${urlFreightForwardService}/GetAllFFBookingConfirmationReport/${param.id}`) :  await axios.get(`${urlFreightForwardService}/GetAllFFDepartureNoticeReport/${param.id}`)
  const selectedDN = param.rid !== undefined ? resAllDN.data.find(e => e.id === param.rid) : null
  const generatedBy = responseGenerated.data.length > 0 ? await axios.get(`${urlIdentityService}/${selectedDN !== null ? selectedDN.generatedBy : responseGenerated.data[0].generatedBy}`) : null
  return {
    departureNoticeReport: selectedDN !== null ? selectedDN.jsonReport : responseReport.data.data.allData,
    allDepartureNotice: resAllDN.data,
    generatedDate : selectedDN !== null ? selectedDN.generatedDate : responseGenerated.data.length > 0 ? responseGenerated.data[0].generatedDate : null,
    generatedBy : generatedBy !== null ? generatedBy.data.data[0] : null,
    ffRequestId : response.data.ffRequestId,
    ffNumber : response.data.ffNumber,
    noOfContainer: response.data.noOfContainer,
    customerName:  response.data.customerName,
    shipperRefNumber : response.data.shipperRefNumber,
    goodsCollectedFrom: response.data.source,
    etdOrigin: response.data.etdOrigin,
    etaPortOfDestination: response.data.etaPortOfDestination,
    dLoaded: true
  }
})

// /ConfigCache/ContainerSize
export const getContainerSizeData = createAsyncThunk(
  "companyConfig/getContainerSizeData",
  async () => {
    const response = await axios.get(`${urlConfigCacheService}?type=ContainerSize`)
    return response.data ? response.data.sort((a, b) => a.id.localeCompare(b.id)) : []
  }
)

export const getAllWeightCertificateList = createAsyncThunk('cleanlinessCertificate/getAllWeightCertificateList', async (data) => {
  const { params, filter } = data

  const uri = getUriParams(params)

  const filterData = {
    transloadOrderNumber: filter.transloadOrderNumber || null,
    bookingNumber: filter.bookingNumber || null,
    vesselName: filter.vesselName || null,
    voyageNumber: filter.voyageNumber || null,
    terminalName: filter.terminalName || null,
    customer: filter.customer || null
  }

  const response = await axios.post(`${urlCleanlinessCertificate}/GetAllWeightCertificateList?${uri}`, filterData)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount,
    filterData
  }
})

export const GetCompanyByCode = createAsyncThunk('cleanliness/GetCompanyByCode', async (terminal) => {
  const response = await axios.get(`${urlCRMCompanyService}/GetCompanyByCode/${terminal}`)
  return response.data
})

export const getAllDataSummaryReport = createAsyncThunk('datasummaryreport/getAllDataSummaryReport', async data => {
  const { params, filter } = data
  const uri = getUriParams(params)
  const filterData = {
    bookingNumber: filter.bookingNumber || null,
    fromDate: filter.fromDate || null,
    toDate: filter.toDate || null
  }
  const response = await axios.post(`${urlReportingService}/GetAllDataSummaryReport?${uri}`, filterData)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount,
    filterData
  }
})

export const getAllDataSummaryStats = createAsyncThunk('datasummaryreport/getAllDataSummaryStats', async () => {
  const response = await axios.post(`${urlReportingService}/GetAllDataSummaryStats`, {})
  return {
    data: response.data
  }
})

export const getAllCleaningLogReport = createAsyncThunk('cleaninglog/getAllCleaningLogReport', async data => {
  const { params, filter } = data
  const uri = getUriParams(params)
  const filterData = {
    fromEquipment: filter.fromEquipment || null,
    toEquipment: filter.toEquipment || null,
    fromDate: filter.fromDate || null,
    toDate: filter.toDate || null
  }
  const response = await axios.post(`${urlReportingService}/GetAllCleaningLogReport?${uri}`, filterData)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount,
    filterData
  }
})

export const ffRequestsSlice = createSlice({
  name: 'ffRequests',
  initialState: {
    data: [],
    total: 1,
    params: {},
    filter: {},
    allData: {},
    selectedCustomer: null,
    ffRequest: null,
    status: [],
    oceanBookings: [],
    oceanBookingList: [],
    shipmentProgressReport: [],
    cleanlinessCertificate: [],
    datasummaryreportstats: [],
    notes: [],
    dLoaded: false,
    dError: false,
    generatedDate: null,
    generatedBy: null,
    ffRequestId: null,
    ffNumber: null,
    shipperRefNumber: null,
    goodsCollectedFrom: null,
    allDepartureNotice: [],
    inboundSummary: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getFFRequest.fulfilled, (state, action) => {
        state.selectedCustomer = action.payload.selectedCustomer
        state.ffRequest = action.payload.ffRequest
        state.status = action.payload.status
        state.oceanBookings = action.payload.oceanBookings
        state.notes = action.payload.notes
      })
      .addCase(getOceanBookingList.fulfilled, (state, action) => {
        state.oceanBookingList = action.payload.oceanBookingList
      })
      .addCase(getShipmentProgress.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getShipmentProgressCount.fulfilled, (state, action) => {
        state.shipmentProgressReport = action.payload.data
      })
      .addCase(getAllCleanlinessCertificateList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getInboundData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.filter = action.payload.filterData
      })
      .addCase(getFFReport.fulfilled, (state, action) => {
        state.dLoaded = action.payload.dLoaded
        state.departureNoticeReport = {...action.payload.departureNoticeReport, generatedDate: action.payload.generatedDate, generatedBy: action.payload.generatedBy, shipperRefNumber: action.payload.shipperRefNumber, ffNumber: action.payload.ffNumber, ffRequestId: action.payload.ffRequestId, goodsCollectedFrom: action.payload.goodsCollectedFrom, noOfContainer: action.payload.noOfContainer, customerName:  action.payload.customerName, etdOrigin:  action.payload.etdOrigin, etaPortOfDestination:  action.payload.etaPortOfDestination}
        state.generatedDate = action.payload.generatedDate
        state.generatedBy = action.payload.generatedBy
        state.ffRequestId = action.payload.ffRequestId
        state.ffNumber = action.payload.ffNumber
        state.shipperRefNumber = action.payload.shipperRefNumber
        state.goodsCollectedFrom = action.payload.goodsCollectedFrom
        state.allDepartureNotice = action.payload.allDepartureNotice
      })
      .addCase(getInboundTracingReportSummary.fulfilled, (state, action) => {
        state.inboundSummary = action.payload.data
      })
      .addCase(getFFReport.rejected, (state) => {
        state.dLoaded = true
        state.dError = true
      })
      .addCase(getContainerSizeData.fulfilled, (state, action) => {
        state.containerSizes = action.payload
      })
      .addCase(getAllWeightCertificateList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getAllDataSummaryReport.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.filter = action.payload.filterData
      })
      .addCase(getAllDataSummaryStats.fulfilled, (state, action) => {
        state.datasummaryreportstats = action.payload.data
      })
      .addCase(getAllCleaningLogReport.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.filter = action.payload.filterData
      })
  }
})

export default ffRequestsSlice.reducer
